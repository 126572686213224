// Libs
import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import CustomerService from 'services/CustomerService';
import ClientListService from 'services/ClientListService';
import SMSService from 'services/SMSService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';
import InsertDynamicFieldButton from 'components/reusable/InsertDynamicFieldButton';
import NotificationService from '../services/NotificationService';

//--------------------------------------------------------------------------------------

export default class SendSMSModal extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };

        this.formHelper = new FormHelper({
            fields: {
                communicationType: {
                    label: 'Type of communication',
                    type: 'single-select',
                    getOptions: () => [
                        { id: 'marketing', name: 'Marketing' },
                        { id: 'functional', name: 'Functional' }
                    ]
                },
                message: {
                    label: 'Message',
                    type: 'multiline-text',
                    rows: 2,
                    getLabelExtras: (fieldInfo, fieldRef) =>
                        <InsertDynamicFieldButton
                            showAppointmentRelated={!!this.state.sms.customerID}
                            showWaitingApptRelated={this.state.sms.waitingApptID}
                            showFormRelated
                            isSMS
                            fieldRef={fieldRef}
                            onSelect={(value) => {
                                this.updateField('message', value, () => {
                                    this.updatePreview();
                                });
                            }}
                        />
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.sms[fieldName],
            setValue: (fieldName, value, fieldInfo) => this.updateField(fieldName, value),
            setClassName: (value) => value ? SMSService.getCredits(value) > 1 : false
            //onBlur: (fieldName) => this.debounceUpdatePreview()
        });
    }

    show(opts) {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                error: null,
                preview: null,
                isLoadingPreview: false,
                notificationID: opts.notificationID,
                sms: {
                    customerID: opts.customerID,
                    appointmentID: opts.appointmentID,
                    clientListID: opts.clientListID,
                    waitingApptID: opts.waitingApptID,
                    toNumber: opts.toNumber,
                }
            }, () => {
                this.load();
            });
        });
    }

    hide() {
        this.setState({
            isOpen: false
        });
    }

    async load() {
        const {
            sms
        } = this.state;
        const newState = {
            isLoading: false,
            sms: sms || {}
        };

        // Load recipient
        if (sms.customerID) {
            newState.customer = await CustomerService.get(sms.customerID);
            newState.sendTo = 'customer';
            newState.sms.communicationType = 'functional';
        }
        else if (sms.clientListID) {
            newState.clientList = await ClientListService.get(sms.clientListID);
            newState.sendTo = 'clientList';
            newState.sms.communicationType = 'marketing';
        } else {
            newState.sendTo = 'customer';
            newState.sms.communicationType = 'functional';
        }

        // Load numbers
        newState.smsNumbers = await SMSService.listNumbers();
        if (newState.smsNumbers.length > 0) {
            newState.sms.smsNumberID = newState.smsNumbers[0].id;
        }
        
        this.setState(newState);
    }
    
    updateField(fieldName, value, callback) {
        const sms = { ...this.state.sms };
        if (fieldName == 'message') {
            value = (value || '').replace(/[^\x00-\xFF]+/g, ''); // Remove non-ASCII
        }
        sms[fieldName] = value;
        this.setState({ sms }, () => {
            this.debounceUpdatePreview();
            if (callback) callback();
        });
    }
    
    async send() {
        this.setState({ isLoading: true });
        await SMSService.send(this.state.sms);
        BootboxHelper.alert('SMS message(s) sent');
        if (this.state.notificationID) {
            await NotificationService.repliedNotification(this.state.notificationID);
            this.props.notificationsReload();
        }
        this.hide();
        this.resolve();
    }

    debounceUpdatePreview() {
        clearTimeout(this.updatePreviewTimeout);
        //if (this.updatePreviewTimeout) return;
        this.updatePreviewTimeout = setTimeout(() => {
            this.updatePreview();
            this.updatePreviewTimeout = null;
        }, 250);
    }

    async updatePreview() {
        this.setState({
            isLoadingPreview: true
        });
        let preview;
        try {
            preview = await SMSService.preview(this.state.sms);
        } catch (ex) {
            preview = ex;
        }
        this.setState({
            preview,
            isLoadingPreview: false
        });
    }

    //---------------------------------------------------------------------

    render() {
        const {
            isOpen,
            isLoading,
            preview,
            sms
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal
                show
                className="send-sms-modal"
                onHide={e => this.setState({ isOpen: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Send SMS
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {this.renderInner()}

                </Modal.Body>
                <Modal.Footer>
                    {!isLoading && <>
                        <button type="button" className="button button-secondary mr-auto" onClick={e => this.setState({ isOpen: false })}>
                            Cancel
                        </button>
                        <button type="button" className="button button-primary ml-auto" disabled={!preview || !sms.smsNumberID} onClick={e => this.send()}>
                            Send
                        </button>
                    </>}
                </Modal.Footer>
            </Modal>
        );
    }

    renderInner() {
        const {
            isLoading,
            sendTo,
            customer,
            clientList,
            preview,
            sms,
            smsNumbers
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        
        return (<>

            {sms.clientListID &&
                this.formHelper.renderFormGroups([
                    'communicationType'
                ])
            }

            <div className="row">

                <div className="col-7">
                    
                    <div className="form-group">
                        <label>Send to</label>
                        <p className="form-control-plaintext">
                            {sendTo == 'customer' && customer && <>
                                {customer.firstName}{' '}
                                {customer.lastName}{' '}
                                ({customer.mobileTelNormalised})
                            </>}
                            {sendTo == 'customer' && !customer && <>
                                ({sms.toNumber})
                            </>}
                            {sendTo == 'clientList' && <>
                                {clientList.name}{' '}
                                {sms.communicationType == 'functional' &&
                                    <>({clientList.numClientsForSMS} {clientList.numClientsForSMS == 1 ? 'client' : 'clients'} with a mobile number)</>
                                }
                                {sms.communicationType == 'marketing' &&
                                    <>({clientList.numClientsOptInSMS} {clientList.numClientsOptInSMS == 1 ? 'client' : 'clients'} opted-in)</>
                                }
                            </>}
                        </p>
                    </div>

                </div>

                <div className="col-5">

                    <div className="form-group">
                        <label>Send from</label>
                        <select
                            value={sms.smsNumberID}
                            onChange={e => this.updateField('smsNumberID', e.target.value)}
                        >
                            {smsNumbers.map(sn =>
                                <option key={sn.id} value={sn.id}>
                                    {sn.numberOrSenderID}
                                </option>
                            )}
                        </select>
                    </div>

                </div>

            </div>

            {this.formHelper.renderFormGroups([
                'message'
            ])}

            <div className="form-group">
                <label>Preview</label>
                <div className="sms-preview">
                    {preview || <>(SMS message is empty)</>}
                </div>
                {!!preview &&
                    <div className="sms-indicator">
                        {SMSService.getSMSLengthMessage(preview)}
                        {(SMSService.containsFields(preview) || this.clientListID) && <>
                            {' '}(* This is an estimate and may vary depending on client details)
                        </>}
                    </div>
                }
            </div>

            <hr />

            <div className="alert alert-danger">
                Please make sure you have enough credit in your SMS account!
            </div>
            
        </>);
    }

}
