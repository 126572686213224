// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';

// Services & Helpers
import PromoCodeService from 'services/PromoCodeService';
import ServiceService from 'services/ServiceService';
import FormHelper from 'helpers/FormHelper';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';

class SettingsPromoCodeEdit extends FloomlyComponent {
    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            promoCode: null
        };

        this.updateField = this.updateField.bind(this);
        this.formHelper = new FormHelper({
            fields: {
                name: {
                    label: 'Name'
                },
                code: {
                    label: 'Code'
                },
                apptDateCheck: {
                    label: 'Appointment date check',
                    type: 'single-select',
                    getOptions: () => {
                        return [
                            { id: '', name: '(Select)' },
                            { id: 'bookingDate', name: 'Booking date' },
                            { id: 'appointmentDate', name: 'Appointment date' }
                        ];
                    }
                },
                latestBookingDate: {
                    type: 'date',
                    label: 'Appointment must be booked by this date'
                },
                latestAppointmentDate: {
                    type: 'date',
                    label: 'Appointment must have taken place by this date'
                },
                isValidAfterExpiry: {
                    type: 'checkbox',
                    label: 'Can use after expiry'
                },
                isActive: {
                    type: 'checkbox',
                    label: 'Active',
                    default: true
                },
                discountType: {
                    label: 'Discount type',
                    type: 'single-select',
                    getOptions: () => {
                        return [
                            { id: '', name: '(Select)' },
                            { id: 'fixed', name: 'Fixed' },
                            { id: 'percentage', name: 'Percentage' }
                        ];
                    }
                },
                discountAmount: {
                    label: () => (this.state.promoCode.discountType == 'fixed' ? 'Discount amount' : 'Discount percentage'),
                    type: 'number'
                },
                promoServiceApplicability: {
                    label: 'Promo code is applicable to..',
                    type: 'single-select',
                    getOptions: () => {
                        return [
                            { id: 'all', name: 'All services' },
                            { id: 'selected', name: 'Selected services' }
                        ];
                    }
                },
                servicePromoCodeIDs: {
                    label: 'Services',
                    type: 'multi-select',
                    blankText: '(No services)',
                    getOptions: () => this.state.services.map(s => ({
                        id: s.serviceID,
                        name: s.name
                    }))
                },
                useForPackage: {
                    type: 'checkbox',
                    label: 'Allow clients to use this promo code with packages'
                },
                numToRedeem: {
                    label: '# times can be used',
                    type: 'number'
                },
                isOnline: {
                    type: 'checkbox',
                    label: 'Can use it online'
                },
                isSalon: {
                    type: 'checkbox',
                    label: 'Can use in salon'
                },
            },
            getValue: (fieldName, fieldInfo) => {
                const { promoCode } = this.state;
                let value = promoCode[fieldName];
                return value;
            },
            setValue: this.updateField
        });
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const promoCodeID = Number(this.props.match.params.promoCodeID) || 0;
        const services = await ServiceService.list();

        let promoCode;
        if (promoCodeID) {
            promoCode = await PromoCodeService.get(promoCodeID);
        } else {
            promoCode = {
                isActive: true,
                isOnline: true,
                isSalon: true,
                promoServiceApplicability :'all'
            };
        }

        // Update UI
        this.setState({
            isLoading: false,
            services,
            promoCode
        });
    }

    goBack() {
        this.props.history.push('/settings/promo-codes');
    }

    async save() {
        this.setState({ isLoading: true });
        const promoCode = { ...this.state.promoCode };
        try {
            if (!promoCode.name) {
                BootboxHelper.alert('Please enter the name');
                return;
            }
            else if (!promoCode.code) {
                BootboxHelper.alert('Please enter the promo code');
                return;
            }
            else if (!promoCode.discountType) {
                BootboxHelper.alert('Please select the discount type');
                return;
            }
            else if (!promoCode.discountAmount) {
                BootboxHelper.alert('Please select the discount amount or percentage');
                return;
            }
            else if (promoCode.promoServiceApplicability == 'selected' && promoCode.servicePromoCodeIDs.length <= 0) {
                BootboxHelper.alert('Please select the services to apply the promo code');
                return;
            }
            await PromoCodeService.save(promoCode);
            this.goBack();
        } catch (error) {
            BootboxHelper.alert(error);
            return;
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this promo code?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        try {
            await PromoCodeService.delete(this.state.promoCode.promoCodeID);
            this.props.history.replace('/settings/promo-codes');
        } catch (e) {
            BootboxHelper.alert(e);
        }
    }

    updateField(fieldName, value, fieldInfo) {
        const promoCode = { ...this.state.promoCode };

        promoCode[fieldName] = value;
        this.setState({ promoCode });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        const {
            promoCode
        } = this.state;

        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section">
                    <button className="button" onClick={() => this.goBack()}>
                        <span className="fa fa-chevron-left"></span>{' '}
                        Back
                    </button>
                </div>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    {promoCode.promoCodeID ? promoCode.name : 'New promo code'}
                </div>

                <div className="info-bar-panel-section ml-auto" style={{ whiteSpace: 'nowrap' }}>
                    {promoCode.promoCodeID &&
                        <button className="button" style={{ marginRight: 10 }} onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times"></span>{' '}
                            Delete
                        </button>
                    }

                    <button className="button" onClick={() => this.save()}>
                        <span className="fa fa-check"></span>{' '}
                        Save
                    </button>

                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            promoCode
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">
                        {this.formHelper.renderFormGroups([
                            'name',
                            'code',
                            'apptDateCheck'
                        ])}
                        {(promoCode.apptDateCheck == 'bookingDate') && <> {this.formHelper.renderFormGroups(['latestBookingDate'])}</>}
                        {(promoCode.apptDateCheck == 'appointmentDate') && <>{this.formHelper.renderFormGroups(['latestAppointmentDate'])}</>}
                        {(promoCode.latestAppointmentDate || promoCode.latestBookingDate)
                            && <>{this.formHelper.renderFormGroups(['isValidAfterExpiry'])} </>
                        }
                        {this.formHelper.renderFormGroups([
                            'promoServiceApplicability'
                        ])}
                        {(promoCode.promoServiceApplicability == 'selected') && <> {this.formHelper.renderFormGroups(['servicePromoCodeIDs'])}</>}
                        {this.formHelper.renderFormGroups([
                            'discountType',
                            'discountAmount',                            
                            'numToRedeem',
                            'useForPackage',
                            'isActive',
                            'isOnline',
                            'isSalon'
                        ])}
                       

                    </div>
                </div>
            </div>
        </>)
    }
}
export default withRouter(SettingsPromoCodeEdit);