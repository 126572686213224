// Libs
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/en-gb';

// Services & Helpers
import DiaryService         from 'services/DiaryService';
import CustomerService      from 'services/CustomerService';
import PromoCodeService     from 'services/PromoCodeService';

// Components
import FloomlyComponent     from 'components/FloomlyComponent';
import Loader               from 'components/reusable/Loader';
import Money                from 'components/reusable/Money';

//-------------------------------------------------------------------------------------------------------------------

class CustomerApptList extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type != prevProps.type) {
            this.load();
        }
    }

    async load() {
        const { type } = this.props;
        this.setState({
            isLoading: true
        });

        const appointments = await CustomerService.listAppointments(this.props.id, this.props.type);
        const retailPurchases = await CustomerService.listWalkInRetail(this.props.id, this.props.type);
        const refunds = await CustomerService.listRefunds(this.props.id, this.props.type);
        const promoCodes = await PromoCodeService.list();

        // Group by date
        let dates = {};
        const initDate = (date) => {
            const dateKey = moment(date).format('YYYY-MM-DD');
            if (!dates[dateKey]) {
                dates[dateKey] = {
                    date,
                    appointments: [],
                    retailPurchases: [],
                    refunds: []
                };
            }
            return dateKey;
        };
        appointments.forEach(a => {
            const dateKey = initDate(a.date);
            dates[dateKey].appointments.push(a);
        });
        retailPurchases.forEach(rp => {
            const dateKey = initDate(rp.date);
            dates[dateKey].retailPurchases.push(rp);
        });
        refunds.forEach(r => {
            const dateKey = initDate(r.date);
            dates[dateKey].refunds.push(r);
        });
        dates = Object.values(dates);
        dates.sort((a, b) => {
            a = parseInt(moment(a.date).format('YYYYMMDD'));
            b = parseInt(moment(b.date).format('YYYYMMDD'));
            return (type == 'future' ? (a - b) : (b - a));
        });

        this.setState({
            dates,
            promoCodes,
            isLoading: false
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        return (<>

            <div className="tab-panel appt-history">
                <div className="panel tab-panel-content">

                    {this.renderInner()}
                    
                </div>
            </div>
        </>);
    }

    renderInner() {
        const {
            type
        } = this.props;
        const {
            isLoading,
            dates
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
   
        return (<>

            {dates.length == 0 &&
                <div className="empty-text">
                    No {type} appointments to show
                </div>
            }

            {dates.map(date =>
                <div className="appt-history-date" key={date.date}>
                    <div className="appt-history-header">
                        {moment(date.date).format('ddd, DD MMMM, YYYY')}

                        <Link className="button button-primary button-small" to={'/diary/' + moment(date.date).format('YYYY-MM-DD') + '/' + (date.appointments.length > 0 ? date.appointments[0].appointmentID : '')}>
                            View in Diary
                        </Link>

                    </div>
                    {/*<div className="appt-history-content">*/}
                    <div className="">
                        {date.appointments.map(a => this.renderAppointment(a))}
                        {date.retailPurchases.map(rp => this.renderRetailPurchase(rp))}
                        {date.refunds.map(r => this.renderRefund(r))}
                    </div>
                </div>
            )}

        </>);
    }

    renderAppointment(appt) {
        let promoDiscountAmount = appt.promoAmount ?? 0;
        var isPromoService = false;
        let code = '';
        if (appt.promoCodeID) {
            const promoCode = this.state.promoCodes.find(ap => ap.promoCodeID == appt.promoCodeID);
            code = promoCode ? promoCode.code : null;
            //for old appointments which has promo codes ( without applicability - can remove in future)
            if (promoCode && appt.promoAmount <= 0) {
                promoDiscountAmount = DiaryService.applyPromoAmount(this.state.promoCodes, appt.promoCodeID, appt.total);
            }
            appt.appointmentServices.forEach((asv) => {
                var servPromoAmount = 0;
                if (promoCode) {
                    if (promoCode.promoServiceApplicability == 'selected') {
                        isPromoService = promoCode.servicePromoCodeIDs.includes(asv.service.serviceID);
                    }
                    else if (promoCode.promoServiceApplicability == 'all') {
                        isPromoService = true;
                    }

                    if (asv.package && isPromoService) {
                        isPromoService = promoCode.useForPackage;
                    }

                    if (isPromoService) {
                        servPromoAmount = DiaryService.applyPromoAmount(this.state.promoCodes, appt.promoCodeID, asv.total);
                    }
                    asv.promoAmount = promoCode.promoServiceApplicability == 'all' && promoCode.discountType == 'fixed' ? servPromoAmount / appt.appointmentServices.length : servPromoAmount;
                }
            });
        }
        appt.total -= promoDiscountAmount;
        const balance = Math.round((appt.total - appt.amountPaid) * 100) / 100;
        let packageName = null;

        return (
            <ul key={appt.appointmentID} className="fv2-appt-details-list">

                <li className="heading">Status</li>
                <li className="one-liner">
                    <div className="icon">
                        <div className={'status-icon ' + DiaryService.getAppointmentStatusClass(appt)}></div>
                    </div>
                    <div className="text">
                        {DiaryService.getAppointmentStatusFriendly(appt)}
                    </div>
                </li>

                <li className="heading">Services</li>
                {appt.appointmentServices.map((asv, index) => {
                    const newPackageName = asv.package?.name;
                    const content = (
                        <React.Fragment key={index}>
                            {newPackageName != packageName &&
                                <li>
                                    {newPackageName && <>
                                        <div className="icon">

                                            <span className="fa-solid fa-boxes-stacked" />

                                        </div>
                                        <div className="text">
                                            <strong>Package: {newPackageName}</strong>
                                        </div>
                                    </>
                                    }
                                </li>
                            }
                            <li>
                                <div className="icon">

                                    <span className="fa-solid fa-scissors" />

                                </div>
                                <div className="text" style={{ paddingLeft: newPackageName ? 30 : 0 }}>
                                    <strong>{asv.service.name}</strong><br />
                                    {!!asv.time && moment(asv.time).format('HH:mm')}
                                    {!!asv.stylistName && <>
                                        {' '}with {asv.stylistName}
                                    </>}
                                </div>
                                <div className="price">
                                    {asv.promoAmount > 0 && <span className="old-price" style={{ paddingRight: 4 }}>
                                        <Money amount={asv.total} />
                                    </span>
                                    }
                                    <span>
                                        <Money amount={asv.total - (asv.promoAmount || 0)} />
                                    </span>
                                </div>
                            </li>
                        </React.Fragment>
                    );
                    packageName = newPackageName;
                    return content;
                })}

                {!!appt.colourNotes &&
                    <li>
                        <div className="icon" title="Colour notes">
                            <span className="fa-solid fa-droplet" />
                        </div>
                        <div className="text">
                            {this.renderMultiLineNotes(appt.colourNotes)}
                        </div>
                    </li>
                }

                {!!appt.notes &&
                    <li>
                        <div className="icon">
                            <span className="fa-regular fa-clipboard" />
                        </div>
                        <div className="text">
                            {this.renderMultiLineNotes(appt.notes)}
                        </div>
                    </li>
                }

                {appt.appointmentPurchases.length > 0 && <>
                    <li className="heading">Retail Purchased</li>
                    {appt.appointmentPurchases.map((ap, index) =>
                        <li key={index}>
                            <div className="icon">
                                <span className="fa-solid fa-bottle-droplet" />
                            </div>
                            <div className="text">
                                {ap.stockItem.product.name}
                                {ap.stockItem.size > 0 && <>
                                    <br />
                                    {ap.stockItem.size}{ap.stockItem.product.stockUnit}
                                </>}
                            </div>
                            <div className="price">
                                <Money amount={ap.total} />
                            </div>
                        </li>
                    )}
                </>}

                {appt.appointmentPayments.length > 0 && <>
                    <li className="heading">{appt.status == 'checkedOut' ? 'Payments' : 'Deposits'}</li>
                    {appt.appointmentPayments.map((ap, index) =>
                        <li key={index}>
                            <div className="icon">
                                <span className="fa-solid fa-cash-register" />
                            </div>
                            <div className="text">
                                {ap.isDeposit ?
                                    (ap.paymentMethodName ? `Deposit (${ap.paymentMethodName})` : 'Deposit') :
                                    (ap.paymentMethodName || 'Unspecified payment method')
                                }
                                {ap.date != appt.date && <>
                                    {' '}on {moment(ap.date).format('DD/MM/YYYY')}
                                </>}
                            </div>
                            <div className="price">
                                <Money amount={ap.amount} />
                            </div>
                        </li>
                    )}
                </>}

                {/* BALANCE */}
                {appt.amountPaid != 0 && balance != 0 && <>
                    <li>
                        <div className="icon">
                            <span className="fa-solid fa-scale-balanced" />
                        </div>
                        <div className="text">
                            {appt.status == 'checkedOut' ? 'Overdue Balance' : 'Balance'}
                        </div>
                        <div className="price">
                            <Money amount={balance} />
                        </div>
                    </li>

                </>}

                {promoDiscountAmount > 0 && <>
                    <li>
                        <div className="icon">
                            <span className="fa-solid fa-gift" />
                        </div>
                        <div className="text">
                            Promo discount amount <span> {' (' + code + ')'}</span>
                        </div>
                        <div className="price">
                            <Money amount={promoDiscountAmount} />
                        </div>
                    </li>
                </>}
                {appt.cancelAmountPaid && <>
                    <li>
                        <div className="icon">
                            <span className="fa-solid fa-money-bill" />
                        </div>
                        <div className="text">
                            Cancel amount paid:
                        </div>
                        <div className="price">
                            <Money amount={appt.cancelAmountPaid} />
                        </div>
                    </li>
                </>}
            </ul>
        );
    }

    renderRetailPurchase(retailPurchase) {
        return (
            <ul key={retailPurchase.retailPurchaseID} className="fv2-appt-details-list">
                <li className="heading">Retail Purchased</li>
                {retailPurchase.stockItems.map((rpp, index) =>
                    <li key={index}>
                        <div className="icon">
                            <span className="fa-solid fa-bottle-droplet" />
                        </div>
                        <div className="text">
                            {rpp.stockItem.product.name}
                            {rpp.stockItem.size > 0 && <>
                                <br />
                                {rpp.stockItem.size}{rpp.stockItem.product.stockUnit}
                            </>}
                        </div>
                        <div className="price">
                            <Money amount={rpp.total} />
                        </div>
                    </li>
                )}

                <li className="heading">Payments</li>
                {retailPurchase.payments.map((rpp, index) =>
                    <li key={index}>
                        <div className="icon">
                            <span className="fa-solid fa-cash-register" />
                        </div>
                        <div className="text">
                            {rpp.paymentMethodName || 'Unspecified payment method'}
                            {/*on {moment(rpp.date).format('DD/MM/YYYY')}*/}
                        </div>
                        <div className="price">
                            <Money amount={rpp.amount} />
                        </div>
                    </li>
                )}

            </ul>
        );
    }

    renderRefund(refund) {
        return (
            <ul key={refund.id} className="fv2-appt-details-list">
                <li className="heading">Refund</li>

                <li>
                    <div className="icon">
                        <span className="fa-solid fa-sad-tear" />
                    </div>
                    <div className="text">
                        {refund.appointment && <>
                            Appointment on {moment(refund.appointment.date).format('DD/MM/YYYY')}
                        </>}
                        {refund.retailPurchase && <>
                            Retail purchase on {moment(refund.retailPurchase.date).format('DD/MM/YYYY')}
                        </>}
                        {refund.products.map(rp =>
                            <div key={rp.id}>
                                {rp.stockItem.product.name}
                            </div>
                        )}
                        <br />
                        Issued by {refund.issuedByNickname}
                        {refund.appointment && <>
                            <br /><br />
                            <Link className="button button-primary button-small" to={'/diary/' + moment(refund.appointment.date).format('YYYY-MM-DD') + '/' + refund.appointment.appointmentID}>
                                View
                            </Link>
                        </>}
                    </div>
                    <div className="price">
                        <Money amount={refund.amount} />
                    </div>
                </li>
               
            </ul>
        );
    }

    renderMultiLineNotes(notes) {
        const lines = (notes || '').split(/\r\n|\r|\n/g);
        return lines.map((l, index) =>
            <React.Fragment key={index}>
                {l}{index < lines.length - 1 && <br />}
            </React.Fragment>
        );
    }
}

export default CustomerApptList;