import BaseService      from 'services/BaseService';
import SearchService    from 'services/SearchService';

//-------------------------------------------------------------------------------------------------------------------

export default class CustomerService
    extends BaseService {

    static async get(id) {
        const response = await BaseService.callAPI('customer/get/' + id);
        return response;
    }

    static async save(customer) {
        const response = await BaseService.callAPI('customer/save', customer);
        SearchService.updateNgramIndex(true);
        return response;
    }

    static async getSummary(id) {
        const response = await BaseService.callAPI('customer/get-summary/' + id);
        return response;
    }

    static async getOnlineBookingAccount(id) {
        return BaseService.callAPI('customer/get-online-booking-account/' + id);
    }

    static async confirmOnlineBookingAccount(id) {
        return BaseService.callAPI('customer/confirm-online-booking-account/' + id);
    }

    static async getRatings(customerIDs, endDate) {
        // Get info from server
        const response = await BaseService.callAPI('customer/get-ratings', {
            customerIDs: customerIDs,
            endDate: endDate
        });
        const ratings = response;
        return ratings;
    }

    static async updateNotes(customerID, field, value) {
        const response = await BaseService.callAPI('customer/update-notes/' + customerID, {
            field: field,
            value: value
        });
        return response;
    }

    static async listAppointments(customerID, type) {
        const response = await BaseService.callAPI('customer/list-appointments/' + customerID + '/' + type);
        return response;
    }

    static async listWalkInRetail(customerID, type) {
        const response = await BaseService.callAPI('customer/list-walk-in-retail/' + customerID + '/' + type);
        return response;
    }

    static async listRefunds(customerID, type) {
        const response = await BaseService.callAPI('customer/list-refunds/' + customerID + '/' + type);
        return response;
    }

    static async listAccountTransactions(customerID) {
        const response = await BaseService.callAPI('customer/list-account-transactions/' + customerID);
        return response;
    }

    static async addAccountTransaction(accountTransaction) {
        const response = await BaseService.callAPI('customer/add-account-transaction', accountTransaction);
        return response;
    }

    static async listComms(customerID) {
        const response = await BaseService.callAPI('customer/list-comms/' + customerID);
        return response;
    }

    static async delete(customerID) {
        const response = await BaseService.callAPI('customer/delete/' + customerID);
        SearchService.updateNgramIndex(true);
        return response;
    }

    static async deleteAccountTransaction(id) {
        return BaseService.callAPI('customer/delete-account-transaction/' + id);
    }

    static async merge(id, customerIDs) {
        const result = await BaseService.callAPI('customer/merge/' + id, { customerIDs });
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async getPatchTestDue(id) {
        const response = await BaseService.callAPI('customer/patch-test-due/' + id);
        return response;
    }
}
