// Libs
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// Services & helpers
import SMSService from 'services/SMSService';
import EmailService from 'services/EmailService';
import AutomationService from 'services/AutomationService';
import FormHelper    from 'helpers/FormHelper';
import DateHelpers    from 'helpers/DateHelpers';
import BootboxHelper from 'helpers/BootboxHelper';
import BaseService from 'services/BaseService';
import GlobalStateService from 'services/GlobalStateService';

// Components
import FloomlyComponent  from 'components/FloomlyComponent';
import Loader        from 'components/reusable/Loader';
import InsertDynamicFieldButton from 'components/reusable/InsertDynamicFieldButton';

//-------------------------------------------------------------------------------------------------------------------

class AutomationsTab extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.updateField = this.updateField.bind(this);

        // Init state
        this.state = {
            isLoadingList: true,
            isLoadingTrigger: false
        };

        this.formHelper = new FormHelper({
            fields: {
                automationTriggerID: {
                    label: 'When...',
                    type: 'single-select',
                    getOptions: () => this.state.automationTriggers
                },
                triggerPeriodType: {
                    label: <>&nbsp;</>,
                    type: 'single-select',
                    getOptions: () => [
                        { id: 'days', name: 'Days'  },
                        { id: 'hours', name: 'Hours' }
                    ]
                },
                triggerPeriod: {
                    label: () => 'How ' + (this.state.automationTrigger.timingType == 'before' ? 'far ahead' : 'long after') + '?',
                    type: 'number'
                },
                triggerTime: {
                    label: 'Time of the day',
                    type: 'single-select',
                    getOptions: () => {
                        const times = DateHelpers.listTimes(15);
                        return [{ id: '', name: '(Rolling window)'}].concat(times.map(t => ({
                            id: t.value + ':00',
                            name: t.text
                        })));
                    }
                },
                triggerImmediatelyIfNeeded: {
                    checkboxLabel: 'Send straight away if the next scheduled time is after the appointment starts',
                    type: 'checkbox'
                },
                sendEmail: {
                    checkboxLabel: 'Send an email',
                    type: 'checkbox'
                },
                emailAccountID: {
                    type: 'single-select',
                    label: 'Send using this account',
                    emptyText: '(None selected)',
                    getOptions: () => this.state.emailAccounts.map(ea => ({
                        id: ea.emailAccountID,
                        name: ea.fromEmail
                    }))
                },
                emailSubjectTemplate: {
                    label: 'Subject',
                    type: 'text',
                    getLabelExtras: (fieldInfo, fieldRef) =>
                        <InsertDynamicFieldButton
                            showAppointmentRelated
                            showWaitingApptRelated
                            showFormRelated
                            fieldRef={fieldRef}
                            onSelect={(value) => {
                                this.updateField('emailSubjectTemplate', value);
                                this.updateEmailPreview();
                            }}  
                        />
                },
                emailHeaderTextTemplate: {
                    label: 'Header',
                    type: 'text',
                    getLabelExtras: (fieldInfo, fieldRef) =>
                        <InsertDynamicFieldButton
                            showAppointmentRelated
                            showWaitingApptRelated
                            showFormRelated
                            fieldRef={fieldRef}
                            onSelect={(value) => {
                                this.updateField('emailHeaderTextTemplate', value);
                                this.updateEmailPreview()
                            }}  
                        />
                },
                emailBodyTemplate: {
                    label: 'Body',
                    type: 'multiline-text',
                    rows: 8,
                    getLabelExtras: (fieldInfo, fieldRef) =>                    
                        <InsertDynamicFieldButton
                            showAppointmentRelated
                            showWaitingApptRelated
                            showFormRelated
                            fieldRef={fieldRef}
                            onSelect={(value) => {
                                this.updateField('emailBodyTemplate', value);
                                this.updateEmailPreview()
                            }}  
                        />
                },
                sendSMS: {
                    label: 'What should happen?',
                    checkboxLabel: 'Send an SMS message',
                    type: 'checkbox'
                },
                smsNumberID: {
                    type: 'single-select',
                    label: 'Send using this number',
                    emptyText: '(None selected)',
                    getOptions: () => this.state.smsNumbers.map(sn => ({
                        id: sn.id,
                        name: sn.numberOrSenderID
                    }))
                },
                smsTemplate: {
                    label: 'SMS Message',
                    type: 'multiline-text',
                    rows: 2,
                    getLabelExtras: (fieldInfo, fieldRef) =>
                        <InsertDynamicFieldButton
                            showAppointmentRelated
                            showWaitingApptRelated
                            showFormRelated
                            isSMS
                            fieldRef={fieldRef}
                            onSelect={(value) => {
                                this.updateField('smsTemplate', value);
                            }}  
                        />,
                    getFieldExtras: (fieldInfo, value) =>
                        <div className="sms-indicator">
                            {SMSService.getSMSLengthMessage(value)}
                            {SMSService.containsFields(value) && <>
                                {' '}(* This is an estimate and may vary depending on client details)
                            </>}
                        </div>
                }
            },
            getValue: (fieldName, fieldInfo) => this.state.salonAutomationTrigger[fieldName],
            setValue: this.updateField,
            onBlur: (fieldName) => {
                if (fieldName == 'emailSubjectTemplate' || fieldName == 'emailHeaderTextTemplate' || fieldName == 'emailBodyTemplate') {
                    this.updateEmailPreview();
                }
            },
            setClassName: (value) => value ? SMSService.getCredits(value) > 1 : false
        });
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const automationTriggers = await AutomationService.listTriggers();
        const salonAutomationTriggers = await AutomationService.listSalonTriggers();
        const emailAccounts = await EmailService.listAccounts();
        const smsNumbers = await SMSService.listNumbers();
        this.setState({
            automationTriggers,
            salonAutomationTriggers,
            emailAccounts,
            smsNumbers,
            isLoadingList: false
        });
    }

    async loadSalonTrigger(id) {
        this.setState({
            isLoadingTrigger: true
        });
        const salonAutomationTrigger = await AutomationService.getSalonTrigger(id);
        const automationTrigger = await AutomationService.getTrigger(salonAutomationTrigger.automationTriggerID);
        this.setState({
            salonAutomationTrigger,
            automationTrigger,
            isLoadingTrigger: false
        }, () => {
            this.updateEmailPreview();
        });
    }

    async updateField(fieldName, value, fieldInfo) {
        const salonAutomationTrigger = { ...this.state.salonAutomationTrigger };
        if (fieldName == 'automationTriggerID') {
            const automationTrigger = await AutomationService.getTrigger(value);
            if (automationTrigger.timingType) {
                salonAutomationTrigger.triggerPeriodType = 'days';
                salonAutomationTrigger.triggerPeriod = 2;
            } else {
                salonAutomationTrigger.triggerPeriodType = null;
                salonAutomationTrigger.triggerPeriod = null;
            }
            this.setState({
                automationTrigger
            });
        } else if (fieldName == 'smsTemplate') {
            value = (value || '').replace(/[^\x00-\xFF]+/g, ''); // Remove non-ASCII
        }
        salonAutomationTrigger[fieldName] = value;

        this.setState({
            salonAutomationTrigger
        }, () => {
            if (fieldName == 'sendEmail' && value) {
                this.updateEmailPreview();
            }
        });
    }

    add() {
        const {
            emailAccounts,
            smsNumbers
        } = this.state;

        this.setState({
            salonAutomationTrigger: {
                emailAccountID: (emailAccounts.length > 0 ? emailAccounts[0].emailAccountID : null),
                smsNumberID: (smsNumbers.length > 0 ? smsNumbers[0].id : null)
            }
        }, () => {
            this.updateEmailPreview();
        });
    }

    async save() {
        this.setState({
            isLoadingTrigger: true
        });
        await AutomationService.saveSalonTrigger(this.state.salonAutomationTrigger);
        this.setState({
            isLoadingTrigger: false,
            salonAutomationTrigger: null
        }, () => {
            this.load();
        });
    }

    async updateEmailPreview() {
        const previewMeta = await EmailService.getPreviewMeta(this.state.salonAutomationTrigger, null);
        this.setState({
            emailPreviewSubject: previewMeta.subject
        }, () => {
            if (this.state.salonAutomationTrigger.sendEmail) {
                const salonCode = GlobalStateService.getValue('salonCode');
                let url = `/api/email/get-preview?salon-code=${salonCode}`;
                BaseService.postToIframe({
                    ...this.state.salonAutomationTrigger,
                    ...this.state.emailSettings
                }, url, 'preview-iframe');
            }
        });
    }

    async confirmDelete() {
        const confirm = await BootboxHelper.confirm('Are you sure you want to delete this automated message?');
        if (confirm) {
            this.delete();
        }
    }

    async delete() {
        this.setState({
            isLoadingTrigger: true
        });
        await AutomationService.deleteSalonTrigger(this.state.salonAutomationTrigger.id);
        this.setState({
            isLoadingTrigger: false,
            isLoadingList: true,
            salonAutomationTrigger: null
        });
        this.load();
    }

    async confirmSendTestEmail() {
        const emailAddress = await BootboxHelper.prompt('Send test email to:');
        if (emailAddress) {
            this.sendTestEmail(emailAddress);
        }
    }

    async sendTestEmail(emailAddress) {
        this.setState({
            isLoadingTrigger: true
        });
        await AutomationService.sendTestEmail(this.state.salonAutomationTrigger, emailAddress);
        this.setState({
            isLoadingTrigger: false
        });
    }

    goBack() {
        this.setState({
            salonAutomationTrigger: null,
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoadingList,
            isLoadingTrigger,
            salonAutomationTrigger            
        } = this.state;

        if (isLoadingList || isLoadingTrigger) {
            return (<Loader />);
        }

        return (
            <div className="automation-triggers">

                {salonAutomationTrigger ?
                    this.renderSalonAutomationTrigger() :
                    <>
                        {this.renderList()}

                        <div className="control-panel">
                            <button className="button button-primary" onClick={e => this.add()}>
                                Add an automated message
                            </button>
                        </div>
                    </>
                }

            </div>
        );
    }

    renderSalonAutomationTrigger() {
        const {
            automationTrigger,
            salonAutomationTrigger,
            emailPreviewSubject
        } = this.state;

        return (<>

            <button className="button button-secondary button-small mb-3" onClick={e => this.goBack()}>
                <span className="fa fa-chevron-left" />{' '}
                Go Back
            </button>

            {this.formHelper.renderFormGroups([
                'automationTriggerID'
            ])}

            {automationTrigger && !!automationTrigger.timingType && <>
                <div className="row">

                    <div className="col-md-2">

                        {this.formHelper.renderFormGroup('triggerPeriod')}

                    </div>

                    <div className="col-md-2">

                        {this.formHelper.renderFormGroup('triggerPeriodType')}

                    </div>

                    {salonAutomationTrigger.triggerPeriodType == 'days' && salonAutomationTrigger.triggerPeriod > 0 &&
                        <div className="col-md-2">

                            {this.formHelper.renderFormGroup('triggerTime')}

                        </div>
                    }

                </div>

                {automationTrigger && automationTrigger.code == 'AppointmentUpcoming' && salonAutomationTrigger.triggerPeriodType == 'days' && salonAutomationTrigger.triggerPeriod > 0 && !!salonAutomationTrigger.triggerTime &&
                    <div>

                        {this.formHelper.renderFormGroup('triggerImmediatelyIfNeeded')}

                    </div>
                }

            </>}

            {salonAutomationTrigger.automationTriggerID && <>
                {/* SMS */}
                {this.formHelper.renderFormGroup('sendSMS')}
                {salonAutomationTrigger.sendSMS &&
                    <div>
                        {this.formHelper.renderFormGroups([
                            'smsNumberID',
                            'smsTemplate'
                        ])}
                    </div>
                }

                {/* EMAIL */}
                {this.formHelper.renderFormGroup('sendEmail')}
                {salonAutomationTrigger.sendEmail &&
                    <div className="row email-details-panel">
                        <div className="col-md-6">

                            {this.formHelper.renderFormGroups([
                            'emailAccountID',                               
                            ])}

                            {this.formHelper.renderFormGroups([
                                'emailSubjectTemplate',
                                'emailHeaderTextTemplate',
                                'emailBodyTemplate'                                
                            ])}
                        </div>   
                        <div className="col-md-6">

                            {emailPreviewSubject &&
                                <div className="form-group">
                                    <label>Subject</label>
                                    <input type="text" disabled value={emailPreviewSubject} />
                                </div>
                            }

                            <label>Content</label>
                            <iframe name="preview-iframe" src="about:blank" className="preview-iframe" />

                        </div>
                    </div>
                }
            </>}

            <div className="button-stack">

                <button className="button button-primary me-3" onClick={e => this.save()}>
                    Save Changes
                </button>

                {salonAutomationTrigger.sendEmail &&
                    <button className="button button-secondary me-3" onClick={e => this.confirmSendTestEmail()}>
                        Send Test Email
                    </button>
                }

                {!!salonAutomationTrigger.id &&
                    <button className="button button-secondary button-small" onClick={e => this.confirmDelete()}>
                        Delete
                    </button>
                }

            </div>

        </>);
    }

    renderList() {
        const {
            salonAutomationTriggers
        } = this.state;

        return (<>

            <ul className="fv2-thing-list mb-3">

                {salonAutomationTriggers.map(sat => {

                    // Timing
                    let timing = '';
                    if (sat.timingType && sat.triggerPeriod) {
                        if (sat.timingType == 'after') {
                            timing += 'After ';
                        }
                        if (sat.triggerPeriodType == 'hours') {
                            timing += sat.triggerPeriod + ' hour' + (sat.triggerPeriod != 1 ? 's' : '');
                            if (sat.triggerPeriodType && sat.timingType == 'before') {
                                timing += ' ahead';
                            }
                        } else if (sat.triggerPeriodType == 'days') {
                            timing += sat.triggerPeriod + ' day' + (sat.triggerPeriod != 1 ? 's' : '');
                            if (sat.triggerPeriodType && sat.timingType == 'before') {
                                timing += ' ahead';
                            }
                            if (sat.triggerTime) {
                                timing += ' at ' + DateHelpers.stripSeconds(sat.triggerTime);
                            }
                        }
                    } else {
                        timing = 'Immediately';
                    }

                    // Actions
                    const actions = [];
                    if (sat.sendSMS) {
                        actions.push('SMS');
                    }
                    if (sat.sendEmail) {
                        actions.push('Email');
                    }
                    if (actions.length == 0) {
                        actions.push('(None)');
                    }

                    // Render
                    return (
                        <li className="thing clickable" key={sat.id} onClick={e => this.loadSalonTrigger(sat.id)}>

                            <div className="thing-side">

                                <div className="thing-icon bordered teal fa-solid fa-bullhorn" />

                            </div>

                            <div className="thing-main">

                                <div className="thing-detail heading">
                                    {sat.name}
                                </div>

                                <div className="thing-detail">
                                    Send: {timing}
                                </div>

                                <div className="thing-detail">
                                    Send by: {actions.join(', ')}
                                </div>

                            </div>
                            
                        </li>
                    );
                })}
            </ul>

        </>);
    }

};

export default withRouter(AutomationsTab);