import React from 'react';

import TextHelpers from 'helpers/TextHelpers';

//-------------------------------------------------------------------------------------------------------------------

class Search extends React.Component {

    constructor(props) {
        super(props);

        this.searchTimeout = null;
        this.blurTimeout = null;

        this.inputRef = React.createRef();

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.focus = this.focus.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.onChange = this.onChange.bind(this);

        this.searchState = {}; // Used to track current nonce
        
        this.state = {
            value: '',
            results: null,
            isActive: false
        };
    }

    componentDidMount() {
        this.query = null;
        this.oldQuery = null;
    }

    componentWillUnmount() {
        clearTimeout(this.searchTimeout);
        clearTimeout(this.blurTimeout);
    }

    onChange(newQuery) {
        const { preProcessQuery, search, searchDelay, minLength, filterResults, maxResults } = this.props;
        this.setState({ value: newQuery });
        if (preProcessQuery) {
            newQuery = preProcessQuery(newQuery);
        }
        this.query = newQuery;
        clearTimeout(this.searchTimeout);
        if (newQuery && newQuery.length >= minLength) {
            if (this.oldQuery !== newQuery) {
                this.searchTimeout = setTimeout(() => {
                    search(newQuery, (results) => {
                        if (results) {
                            results = results.filter(r => filterResults(r));
                        }
                        this.setState({ results });
                    }, maxResults, this.searchState);
                }, searchDelay);
                this.oldQuery = newQuery;
            }
        } else {
            this.setState({ results: null });
            this.oldQuery = null;
        }
    }

    onFocus() {
        this.setState({ isActive: true });
    }

    onBlur(e) {
        const { onBlur, blurDelay } = this.props;
        clearTimeout(this.blurTimeout);
        this.blurTimeout = setTimeout(() => {
            this.setState({ isActive: false });
            if (onBlur) onBlur(e);
        }, blurDelay);
    }

    focus() {
        const { blurDelay } = this.props;
        this.blurTimeout = setTimeout(() => {
            if (this.inputRef.current) {
                this.inputRef.current.focus();
                this.onFocus();
            }
        }, blurDelay + 1);
    }

    clearSearch() {
        this.query = null;
        this.oldQuery = null;
        this.setState({ value: '', results: null });
    }

    render() {
        const { renderWrapper, renderResult, className, id, name, style, autoFocus, placeholder, noResultsText, maxResults } = this.props;
        const { isActive, value, results } = this.state;
        const info = {
            query: this.query,
            isActive,
            onInputChange: e => this.onChange(e.target.value),
            focus: this.focus,
            clearSearch: this.clearSearch,
            noResultsText,
            maxResults
        };
        return renderWrapper(
            <input
                ref={this.inputRef}
                className={className}
                id={id}
                name={name}
                style={style}
                autoFocus={autoFocus}
                autoComplete="off"
                placeholder={placeholder}
                value={value || ''}
                onChange={e => this.onChange(e.target.value)}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
            />,
            results && results.map((result, index) => renderResult(result, index, info)),
            info
        );
    }
}

Search.defaultProps = {
    placeholder: 'Start typing to search...',
    noResultsText: 'Sorry, nothing found',
    maxResults: null,
    searchDelay: 200,
    blurDelay: 200,
    minLength: 2,
    preProcessQuery: (query) => TextHelpers.simplifySearchString(query),
    search: (query, setResults) => { console.log('search property not specified') },
    filterResults: (result) => true,

    renderWrapper: (input, results, info) => <>
        {input}
        {results && results.length > 0 &&
            <div className={'list search-results ' + (info.isActive ? 'active' : '')}>{results}</div>
        }
        {results && results.length == 0 && !!info.noResultsText &&
            <div className={'list search-results ' + (info.isActive ? 'active' : '')}>
                <p className="empty-text">{info.noResultsText}</p>
            </div>
        }
    </>,

    renderResult: (result, index, info) =>
        <div key={index} className="search-result">
            {result.name}
        </div>
};

export default Search;

