import React from 'react';
import BaseService from 'services/BaseService';

//-------------------------------------------------------------------------------------------------------------------

export default class SMSService
    extends BaseService {
    
    static async listTypes() {
        const response = await BaseService.callAPI('sms/list-types');
        return response;
    }

    static async preview(sms) {
        return BaseService.callAPI('sms/preview', sms);
    }

    static async send(sms) {
        return BaseService.callAPI('sms/send', sms);
    }

    static async listNumbers() {
        return BaseService.callAPI('sms/list-numbers');
    }

    static getSMSLengthMessage(sms) {
        let numCredits = 0;
        if (sms) {
            numCredits = this.getCredits(sms);
        }        
        return <>{sms.length} / {numCredits * 160} ({numCredits == 1 ? '1 credit' : numCredits + ' credits'})</>;
    }

    static getCredits(sms) {
        let numCredits = 0;
        if (sms) {
            const fieldLengths = {
                '{{\\s*Customer\\.FirstName\\s*}}': 9,
                '{{\\s*Customer\\.LastName\\s*}}': 7,
                '{{\\s*Appointment\\.Date\\s*}}': 10,
                '{{\\s*Appointment\\.StartTime\\s*}}': 8,
                '{{\\s*Appointment\\.QuotedPrice\\s*}}': 7,
                '{{\\s*Appointment\\.DepsitAmount\\s*}}': 6,
                '{{\\s*Appointment\\.Summary\\s*}}': 160,
                '{{\\s*Appointment\\.Balance\\s*}}': 7,
                '{{\\s*Salon\\.Name\\s*}}': 18
            };
            sms = sms || '';
            for (let f in fieldLengths) {
                const length = fieldLengths[f];
                sms = sms.replace(new RegExp(f, 'i'), 'x'.repeat(length));
            }
            numCredits = Math.max(1, Math.ceil(sms.length / 160));
        }
        return numCredits;
    }

    static containsFields(sms) {
        const containsFields = (sms||'').match(new RegExp('{{(.+?)}}', 'i'));
        return !!containsFields;
    }
}
