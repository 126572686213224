// Libs
import React from 'react';
import { withRouter } from 'react-router';
import NumberFormat from 'react-number-format';

// Services & Helpers
import TextHelpers from 'helpers/TextHelpers';
import BootboxHelper from 'helpers/BootboxHelper';
import StockService from 'services/StockService';
import SalonService from 'services/SalonService';
import CompanyService from 'services/CompanyService';


// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';

class CreateNewStockOrderTab extends FloomlyComponent {

    constructor(props) {
        super(props);
        this.NumItemsPerPage = 100;

        this.state = {
            isLoading: true,
            selectedFilters: [],
            stockItems: [],
            selectedStockItems: [],
            stockItemsThisPage: [],
            salonCurrency: '',
            isDraftSaved: false,
            parentStockOrderID: 0,
            companies: [],
            manufacturerID: 0,
            productRangeID: 0,
            usage: '',
            stockAlertLevel: '',
            ranges: []
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        let stockOrderId = 0, parentStockOrderID;
        let stockOrder;

        if (this.props.match.params.subTab && this.props.match.params.subTabParam) {
            if (this.props.match.params.subTabParam == 'draftOrder') {
                parentStockOrderID = this.props.match.params.subTab;
                stockOrder = await StockService.getStockOrder(this.props.match.params.subTab);
                if (stockOrder.status == 'Draft') {
                    this.setState({
                        isDraftSaved: true
                    });
                }
            }
        }
        else if (this.props.match.params.subTab) {
            stockOrderId = this.props.match.params.subTab;
            stockOrder = await StockService.getStockOrder(stockOrderId);
            if (stockOrder.status == 'Draft') {
                this.setState({
                    isDraftSaved: true
                });
            }
        }
        const stockItems = await StockService.getStockItems();
        const currency = SalonService.getCurrentCurrency();
        const companies = await CompanyService.list();
        this.setState({
            salonCurrency: currency,
            companies,
            stockItems,
            pageNum: 0,
            stockOrderId,
            parentStockOrderID,
            numPages: Math.ceil(stockItems.length / this.NumItemsPerPage)
        }, () => {
            this.loadPage(0);
        });

        if (stockOrder) {
            let stockOrderItems = stockOrder.stockOrderItems.filter(so => so.quantityYetToArrive > 0);
            stockOrderItems.map(so => {
                this.updateStockItem(so.stockItem.stockItemID, { isSelected: true });
                this.updateStockItem(so.stockItem.stockItemID, { noOfPacksToOrder: so.quantityYetToArrive });
            });
            const selectedItems = stockItems.filter(s => s.isSelected);
            if (selectedItems.length > 0) {
                this.setState({
                    selectedStockItems: selectedItems
                });
            }
        }
    }

    async loadPage(pageNum) {
        this.setState({
            isLoading: true
        });
        while (pageNum * this.NumItemsPerPage > this.state.stockItems.length) {
            pageNum--;
        }
        const stockItemsThisPage = this.state.stockItems.slice(this.NumItemsPerPage * pageNum, this.NumItemsPerPage * pageNum + this.NumItemsPerPage);
        this.setState({
            stockItemsThisPage,
            pageNum,
            isLoading: false
        });
    }

        addToOrder(stockItemID, value) {
        value = value ? value.toFixed() : '';
        let selectedStockItems = this.state.selectedStockItems;
        let stockItems = this.state.stockItems;
        const index = stockItems.findIndex(s => s.stockItemID == stockItemID);
        const selectedIndex = selectedStockItems.findIndex(s => s.stockItemID == stockItemID);
        const stockItem = { ...stockItems[index] };
        if (value > 0) {
            const isItemExist = selectedStockItems.some(si => si.stockItemID == stockItemID);
            if (!isItemExist) {
                stockItem.noOfPacksToOrder = value;
                stockItem.isSelected = true;
                selectedStockItems.push(stockItem);
            }
            else {
                const stockItemIndex = selectedStockItems.findIndex(s => s.stockItemID == stockItemID);
                let stockItem = { ...selectedStockItems[stockItemIndex] }
                stockItem["noOfPacksToOrder"] = value;
                selectedStockItems[stockItemIndex] = stockItem;
            }
        }
        else {
            if (selectedStockItems.length > 0) {
                selectedStockItems.splice(selectedIndex, 1);
                stockItem.isSelected = false;
            }
        }
        this.setState({
            selectedStockItems
        });
        this.updateStockItem(stockItemID, { noOfPacksToOrder: value });
    }

    async getFilteredStockItems() {
        const {
            manufacturerID,
            productRangeID,
            usage,
            stockAlertLevel,
            selectedStockItems
        } = this.state;
        const stockItems = await StockService.getFilteredStockItems({ manufacturerID, productRangeID, usage, stockAlertLevel });
        if (selectedStockItems.length > 0) {
            selectedStockItems.map(si => {
                const stockItemIndex = stockItems.findIndex(s => s.stockItemID == si.stockItemID);
                let stockItem = { ...stockItems[stockItemIndex] }
                stockItem['noOfPacksToOrder'] = si.noOfPacksToOrder;
                stockItems[stockItemIndex] = stockItem;
            })
        }
        this.setState({
            stockItems,
            pageNum: 0,
            numPages: Math.ceil(stockItems.length / this.NumItemsPerPage)
        }, () => {
            this.loadPage(0);
        });
    }

    updateStockItem(stockItemID, newValues) {
        const stockItems = this.state.stockItems;
        const stockItemsThisPage = this.state.stockItemsThisPage;
        const selectedStockItems = this.state.selectedStockItems;
        const index = stockItems.findIndex(s => s.stockItemID == stockItemID);
        const stockItemIndex = stockItemsThisPage.findIndex(s => s.stockItemID == stockItemID);
        const selectedItemIndex = selectedStockItems.findIndex(s => s.stockItemID == stockItemID);
        const stockItem = { ...stockItems[index] };
        const stockItemThisPage = { ...stockItemsThisPage[stockItemIndex] }
        const selectedStockItem = { ...selectedStockItems[selectedItemIndex] }
        for (let fieldName in newValues) {
            const value = newValues[fieldName];
            if (selectedStockItems.length > 0) {
                selectedStockItem[fieldName] = value;
                selectedStockItems[selectedItemIndex] = selectedStockItem;
            }

            stockItemThisPage[fieldName] = value;
            stockItemsThisPage[stockItemIndex] = stockItemThisPage;
            stockItem[fieldName] = value;
            stockItems[index] = stockItem;
        }


        this.setState({ stockItems, stockItemsThisPage }, () => {
            this.loadPage(this.state.pageNum);
        });
    }

    async saveDraft() {
        await this.save();
        if (this.state.stockOrderId > 0)
            await BootboxHelper.alert('Order saved successfully!');
        //this.props.history.push('/stock/stock-ordering/create-new-stock-order/' + result.stockOrderId);
        this.props.history.push('/stock/stock-ordering/previous-orders-list/');
        this.setState({
            isLoading: false,
            isDraftSaved: true,
        });
    }

    async save() {
        const {
            stockOrderId,
            parentStockOrderID,
            selectedStockItems
        } = this.state;

        this.setState({
            isLoading: true
        });
        let result = await StockService.saveStockOrder(selectedStockItems, stockOrderId, parentStockOrderID);
        this.setState({
            stockOrderId: result.stockOrderId
        });

    }

    async completeOrderAndDownloadExcel() {
        this.setState({
            isLoading: true
        });
        await this.save();
        await StockService.completeStockOrderExcel(this.state.stockOrderId);
        await BootboxHelper.alert('Order saved successfully!');
        this.props.history.push('/stock/stock-ordering/previous-orders-list/');
    }

    async completeOrderAndDownloadCsv() {
        this.setState({
            isLoading: true
        });
        await this.save();
        await StockService.completeStockOrderCsv(this.state.stockOrderId);
        await BootboxHelper.alert('Order saved successfully!');
        this.props.history.push('/stock/stock-ordering/previous-orders-list/');
    }

    setManufacturer(value) {
        this.setState({
            manufacturerID: value,
            productRangeID: 0
        }, () => {
            this.renderProductRanges(value);
            this.getFilteredStockItems();
        });

    }

    renderManufacturer() {
        const { companies, manufacturerID } = this.state;
        return (<>
            <select value={manufacturerID} className="left-margin" onChange={e => this.setManufacturer(e.target.value)}>
                <option key='0' value='0'>All</option>
                {companies.map(company =>
                    <option key={company.id} value={company.id}>
                        {company.name}
                    </option>
                )}
            </select>
        </>)
    }

    async renderProductRanges(manufacturerID) {
        const ranges = await StockService.listRanges(manufacturerID, null);
        this.setState({
            ranges
        })
    }

    renderStockLevel() {
        return (<>
            <select className="left-margin" onChange={e => this.setState({ stockAlertLevel: e.target.value == '0' ? null : e.target.value }, () => this.getFilteredStockItems())}>
                <option key='0' value='0'>All</option>
                <option key='1' value='Red'>Red</option>
                <option key='2' value='Amber'>Amber</option>
                <option key='3' value='Green'>Green</option>
            </select>
        </>)
    }

    renderUsage() {
        return (<>
            <select className="left-margin" onChange={e => this.setState({ usage: e.target.value == '0' ? null : e.target.value }, () => this.getFilteredStockItems())}>
                <option key='0' value='0'>All</option>
                <option key='1' value='Retail'>Retail</option>
                <option key='2' value='Professional'>Professional</option>
            </select>
        </>)
    }

    async confirmResetFilters() {
        const confirm = await BootboxHelper.confirm('Reset all filters?');
        if (confirm) {
            this.resetFilters();
        }
    }

    resetFilters() {
        this.updateFields({ selectedFilters: [] });
    }

    renderOrderSummary() {
        const {
            selectedStockItems
        } = this.state;


        let total = 0;
        selectedStockItems.forEach(s => {
            total += (Number(s.costPrice * s.noOfPacksToOrder) || 0);
        });

        if (selectedStockItems.length == 0) {
            return (
                <div className="please-select-stock-item">
                    Please select a item to order.
                </div>
            );
        }

        return (
            <>
                <p>Your Order:</p>
                <div className="order-summary-group">

                    {selectedStockItems.map(stockItem =>
                        <><React.Fragment key={stockItem.stockItemID}>

                            <div className="order-summary-item">
                                <span className="product-name-icon fa fa-angle-right"></span>
                                <div className="product-name-left">{stockItem.product.name}</div>
                                <div className="order-right">
                                    <button className="stock-button" onClick={e => this.addToOrder(stockItem.stockItemID, Number(stockItem.noOfPacksToOrder) - 1)}> <span className="fa fa-minus"></span></button>
                                    <span className="packs-to-order">{stockItem.noOfPacksToOrder}</span>
                                    <button className="stock-button" onClick={e => this.addToOrder(stockItem.stockItemID, Number(stockItem.noOfPacksToOrder) + 1)}> <span className="fa fa-plus"></span></button></div>
                                <div className="cost-right">{TextHelpers.formatCurrencyNew(stockItem.costPrice * stockItem.noOfPacksToOrder, { includeSymbol: true })}</div>

                            </div>
                        </React.Fragment>

                        </>
                    )}
                    <div className="order-summary-item">
                        <span className={`product-name-icon fas fa-money-bill`}></span>
                        <div className="product-name-left">Total</div>
                        <div className="cost-right">{total > 0 ? TextHelpers.formatCurrencyNew(total, { includeSymbol: true }) : '-'}</div>
                    </div>
                </div>
                <div className="save-draft-button">
                    <button
                        className="button button-secondary button-small save-button me-3"
                        onClick={e => this.saveDraft()}
                    >
                        Save Draft
                    </button>
                    <button
                        className="button button-secondary button-small save-button"
                        onClick={e => this.completeOrderAndDownloadExcel()}
                    >
                        Complete and Download Excel
                    </button>
                    <button
                        className="button button-secondary button-small save-button"
                        onClick={e => this.completeOrderAndDownloadCsv()}
                    >
                        Complete and Download CSV
                    </button>

                </div>
            </>
        );
    }

    renderStockItems() {
        const {
            numPages,
            pageNum,
            stockItemsThisPage, salonCurrency } = this.state;
        const pages = [...new Array(numPages).keys()];
        const pagination = numPages > 1 && (
            <ul className="pagination">
                {pages.map(page =>
                    <li key={page} className={pageNum == page ? 'active' : ''} onClick={e => this.loadPage(page)}>
                        {(page + 1)}
                    </li>
                )}
            </ul>
        );
        return (<>

            {pagination}

            <div className="stock-order-content">

                <section className="stock-table-content-left">

                    <SuperTable
                        className="stock-categorise-table"
                        rows={stockItemsThisPage}
                        keyAccessor={s => s.stockItemID}
                        emptyText="You have no stockItems"
                        rowPropsAccessor={(row, index) => {
                            if (row.noOfPacksToOrder > 0) return { className: 'highlight-stock-item' };
                        }}
                        cols={{
                            name: {
                                label: 'Name',
                                className: 'name-column',
                                getValue: (colInfo, stockItem) => <>
                                    {
                                        <><span>{stockItem.product.name}</span></>
                                    }
                                </>
                            },
                            size: {
                                label: 'Size',
                                className: 'size-column',
                                getValue: (colInfo, stockItem) => <>
                                    {
                                        <><span>{stockItem.size}</span>  {stockItem.product.stockUnit}

                                        </>
                                    }
                                </>
                            },
                            costPrice: {
                                label: 'Price',
                                className: 'price-column',
                                getValue: (colInfo, stockItem) =>
                                    <NumberFormat
                                        thousandSeparator={salonCurrency.thousandSeparator}
                                        decimalSeparator={salonCurrency.decimalSeparator}
                                        prefix={salonCurrency.symbol}
                                        className="has-prefix"
                                        value={stockItem.costPrice || ''}
                                        onValueChange={(e) => this.updateStockItem(stockItem.stockItemID, { costPrice: e.value })}
                                    />
                            },
                            packSize: {
                                label: 'Pack Size',
                                className: 'pack-size-column',
                                getValue: (colInfo, stockItem) =>
                                    <input
                                        type="number"
                                        className="pack-size-column"
                                        value={stockItem.packSize || ''}
                                        onChange={e => this.updateStockItem(stockItem.stockItemID, { packSize: e.target.value })}
                                    />
                            },
                            currentStock: {
                                label: 'In stock',
                                className: 'current-stock-column',
                                getValue: (colInfo, stockItem) => <>
                                    {
                                        <span>{stockItem.currentStock}</span>
                                    }
                                </>
                            },
                            minStock: {
                                label: 'Min',
                                className: 'min-stock-column',
                                getValue: (colInfo, stockItem) => <>
                                    {
                                        <span>{stockItem.minStock}</span>
                                    }
                                </>
                            },
                            idealStock: {
                                label: 'Ideal',
                                className: 'ideal-stock-column',
                                getValue: (colInfo, stockItem) => <>
                                    {
                                        <span>{stockItem.idealStock}</span>
                                    }
                                </>
                            },
                            noOfPacksToOrder: {
                                label: 'Add to Order',
                                className: 'pack-number-column',
                                getValue: (colInfo, stockItem) =>
                                    <>
                                        <div className="add-to-order">
                                            {stockItem.noOfPacksToOrder > 0 &&
                                                <button className="stock-button" onClick={e => this.addToOrder(stockItem.stockItemID, stockItem.noOfPacksToOrder - 1)}>
                                                    <span className="fa fa-minus"></span>
                                                </button>}
                                            <input
                                                type="number"
                                                className="add-to-order-column"
                                                value={stockItem.noOfPacksToOrder || ''}
                                                onChange={e => this.addToOrder(stockItem.stockItemID, Number(e.target.value))}
                                            />
                                            {stockItem.noOfPacksToOrder > 0 &&
                                                <button className="stock-button" onClick={e => this.addToOrder(stockItem.stockItemID, Number(stockItem.noOfPacksToOrder) + 1)}>
                                                    <span className="fa fa-plus"></span>
                                                </button>}
                                        </div>
                                    </>
                            },

                        }}
                    />

                </section>

                <section className="stock-table-content-right" style={{ overflowY: 'auto' }}>

                    <div className="page-content-right-inner">
                        <div className="panel summary-panel">

                            <div className="panel-header">
                                Order Summary
                            </div>

                            <div className="panel-body booking-summary">

                                {this.renderOrderSummary()}

                            </div>

                        </div>

                    </div>
                </section>

            </div>

            {pagination}
        </>);

    }

    async searchProduct(query) {
        const stockItems = [...this.state.stockItems];
        const { selectedStockItems } = this.state;
        const filteredStockItems = (query.length >= 2 ? stockItems
            .filter((stockItem) => {
                return query === ''
                    ? stockItem
                    : stockItem.product.name?.toLowerCase().includes(query.toLowerCase());
            }) : await StockService.getStockItems());

        if (selectedStockItems.length > 0) {
            selectedStockItems.map(si => {
                const stockItemIndex = filteredStockItems.findIndex(s => s.stockItemID == si.stockItemID);
                let stockItem = { ...filteredStockItems[stockItemIndex] }
                stockItem['noOfPacksToOrder'] = si.noOfPacksToOrder;
                filteredStockItems[stockItemIndex] = stockItem;
            })
        }

        this.setState({
            stockItems: filteredStockItems,
            pageNum: 0,
            numPages: Math.ceil(stockItems.length / this.NumItemsPerPage)
        }, () => {
            this.loadPage(0);
        });
    }

    render() {
        const {
            isLoading,
            ranges,
            manufacturerID
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        return (
            <div className="create-new-stock-order">
                <div className="bottom-margin">
                    <section className="filters">
                        {/* <h3>Filters</h3>*/}
                        <div className="filters">
                            <div className="companies-filter right-margin">
                                <span>Manufacturer</span>
                                {this.renderManufacturer()}
                            </div>
                            {manufacturerID > 0 &&
                                <div className="product-range-filter right-margin">
                                    <span>Product range</span>
                                    <select className="left-margin" onChange={e => this.setState({ productRangeID: e.target.value }, () => this.getFilteredStockItems())}>
                                        <option key='0' value='0'>All</option>
                                        {ranges.map(range =>
                                            <option key={range.id} value={range.id}>
                                                {range.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            }
                            <div className="usage-filter right-margin">
                                <span>Usage</span>
                                {this.renderUsage()}
                            </div>
                            <div className="stock-level-filter right-margin">
                                <span>Stock Level</span>
                                {this.renderStockLevel()}
                            </div>
                            <div className="stock-search-bar">
                                <input type="search" placeholder="Type to search..." onChange={e => this.searchProduct(e.target.value)} />
                            </div>
                        </div>
                    </section>
                </div>
                <div className="stock-items-list">
                    {this.renderStockItems()}
                </div>
            </div>

        );
    }
}

export default withRouter(CreateNewStockOrderTab);
