import BaseService from 'services/BaseService';
import SearchService from 'services/SearchService';

//-------------------------------------------------------------------------------------------------------------------

export default class StockService
    extends BaseService {

    static async getStockItem(id) {
        return BaseService.callAPI('product/get-stock-item/' + id);
    }

    static async listCategories(showOnClientRecord) {
        return BaseService.callAPI('stock/list-product-categories', {
            showOnClientRecord
        });
    }
    
    static async listManufacturers(usage) {
        return BaseService.callAPI('stock/list-manufacturers/' + (usage || ''));
    }

    static async listRanges(manufacturerID, usage) {
        return BaseService.callAPI('stock/list-ranges/' + (manufacturerID || '0') + '/' + (usage || ''));
    }

    static async listProducts(rangeID) {
        return BaseService.callAPI('stock/list-products/' + (rangeID || ''));
    }

    static async saveProduct(product) {
        const result = await BaseService.callAPI('stock/save-product', product);
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async saveStockItem(stockItem) {
        const result = await BaseService.callAPI('stock/save-stock-item', stockItem);
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async saveManufacturer(manufacturer) {
        const result = await BaseService.callAPI('stock/save-manufacturer', manufacturer);
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async saveRange(range) {
        const result = await BaseService.callAPI('stock/save-range', range);
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async checkOut(stockItems) {
        return BaseService.callAPI('stock/check-out', {
            stockItems
        });
    }

    static async deleteProduct(id) {
        const result = await BaseService.callAPI('stock/delete-product/' + id);
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async bulkUpdate(products) {
        const result = await BaseService.callAPI('stock/bulk-update', { products });
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async deleteManufacturer(id, usage) {
        const result = BaseService.callAPI('stock/delete-manufacturer/' + id + '/' + usage);
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async deleteRange(id) {
        const result = BaseService.callAPI('stock/delete-range/' + id);
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async getStockItems() {
        return BaseService.callAPI('stock/get-stock-items');
    }

    static async getFilteredStockItems(param) {
        return BaseService.callAPI('stock/get-filtered-stock-items', {
            stockOrderFilter: param
        });
    }

    static async getStockOrders() {
        return BaseService.callAPI('stock/get-stock-orders');
    }

    static async getStockOrder(id) {
        return BaseService.callAPI('stock/get-stock-order/' + id);
    }

    static async saveStockOrder(stockItems, stockOrderId, parentStockOrderId) {
        return BaseService.callAPI('stock/save-stock-order', {
            stockItems, stockOrderId, parentStockOrderId
        });
    }

    static async completeStockOrderExcel(stockOrderId) {
        return BaseService.callAPI('stock/complete-stock-order-excel', { stockOrderId, isDownload: true });
    }

    static async completeStockOrderCsv(stockOrderId) {
        return BaseService.callAPI('stock/complete-stock-order-csv', { stockOrderId, isDownload: true });
    }

    static async saveStockOrderDelivery(stockOrder) {
        return BaseService.callAPI('stock/save-stock-order-delivery', {
            stockOrder
        });
    }

    static async exportStockroom() {
        return BaseService.callAPI('stock/export-stockroom', {isDownload: true});
    }
}
