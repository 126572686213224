import BaseService from 'services/BaseService';
import SearchService from 'services/SearchService';

//-------------------------------------------------------------------------------------------------------------------

export default class ServiceService
    extends BaseService {

    static async list(param) {
        return BaseService.callAPI('service/list', param);
    }

    static async get(serviceID) {
        return BaseService.callAPI('service/get/' + serviceID);
    }

    static async getDetailedInfo(serviceID) {
        const response = await BaseService.callAPI('service/get-detailed-info/' + serviceID);
        return response;
    }

    static async getAutomaticallyAddedServices() {
        return BaseService.callAPI('service/get-automatically-added-services');
    }

    static async save(service) {
        const result = await BaseService.callAPI('service/save', service);
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async delete(id) {
        const result = await BaseService.callAPI('service/delete/' + id);
        SearchService.updateNgramIndex(true);
        return result;
    }

}