import BaseService from 'services/BaseService';
import SearchService from 'services/SearchService';

//-------------------------------------------------------------------------------------------------------------------

export default class RetailService
    extends BaseService {

    static async save(retailPurchase) {
        return BaseService.callAPI('retail/save', retailPurchase);
    }

	static async get(retailPurchaseID) {
		let retailPurchase = await BaseService.callAPI('retail/get/' + retailPurchaseID);
		retailPurchase.stockItems.map(stockItem => stockItem.product = stockItem.stockItem.product);
		return retailPurchase;
	}

	static async delete(retailPurchaseID) {
		return BaseService.callAPI('retail/delete/' + retailPurchaseID);
	}

	static async getRefund(refundID) {
		let refund = await BaseService.callAPI('retail/get-refund/' + refundID);
		refund.stockItems.map(stockItem => stockItem.product = stockItem.stockItem.product);
		return refund;
	}

    static async saveVoucher(voucher) {
        const result = await BaseService.callAPI('retail/save-voucher', voucher);
        SearchService.updateNgramIndex(true);
        return result;
    }

    static async listUnspentVouchersForCustomer(customerID) {
        return BaseService.callAPI('retail/list-unspent-vouchers-for-customer/' + customerID);
    }

    //static async getLatestVoucherForCustomer(customerID) {
    //    return BaseService.callAPI('retail/get-latest-voucher-for-customer/' + customerID);
    //}

    static async generateRandomVoucherCode() {
        return BaseService.callAPI('retail/generate-random-voucher-code');
    }

    static async isVoucherCodeUsed(code) {
        return BaseService.callAPI('retail/is-voucher-code-used/' + code);
    }

    static async saveAcctTran(acctTran) {
        return BaseService.callAPI('retail/save-acct-tran', acctTran);
    }

	static async issueRefund(refund) {
		return BaseService.callAPI('retail/issue-refund', refund);
	}
}